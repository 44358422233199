<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav">
      <el-tab-pane label="用户管理" name="users">
        <!--        搜索查询-->
        <div class="search space-between">
          <div class="flex-nowrap top-list">
            <el-form :inline="true">
              <el-form-item>
                <el-select v-model="options" placeholder="选项" class="search-types">
                  <el-option v-for="item in optionsData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-input v-model="keywords" placeholder="请输入查询条件" class="search-input"></el-input>
              </el-form-item>
              <el-form-item label=" 身份">
                <el-select v-model="identity" placeholder="请选择" class="search-type">
                  <el-option v-for="item in identityData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="">
                <el-select v-model="options_status" placeholder="请选择" class="search-type identity">
                  <el-option v-for="item in optionsStatus" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="状态">
                <el-select v-model="author_status" placeholder="请选择" class="search-type">
                  <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <!--              <el-form-item label="等级">
                              <el-select v-model="level" placeholder="请选择" class="search-type" >
                                <el-option
                                    v-for="item in levelData"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                              </el-select>
                            </el-form-item>-->

              <el-form-item label="注册时间">
                <el-date-picker
                  class="vertical-end"
                  style="width: 269px"
                  @change="timeChange"
                  v-model="change_time"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="footer_btn">
          <el-button type="primary" class="search-btn confirm" @click="searchSubmit" style="margin-left: 30px">查询</el-button>
          <el-button class="search-btn reset" @click="resetFun">重置</el-button>
        </div>
        <div class="total space-between vertical-center">
          <div class="total-left vertical-center">
            共有 <span class="total-left-text">{{ userInfoData.meta ? userInfoData.meta.total : 0 }}</span>
            条数据
          </div>
          <div class="total-right vertical-center" v-if="userInfoData.user_statistics">
            管理员：<span class="total-right-text">{{ userInfoData.user_statistics.admin_number }}</span> 个 &nbsp&nbsp&nbsp主播：<span class="total-right-text">{{
              userInfoData.user_statistics.presenter_number
            }}</span
            >个 &nbsp&nbsp&nbsp创作者：<span class="total-right-text">{{ userInfoData.user_statistics.author_number }}</span> 个 &nbsp&nbsp&nbsp注册用户：<span class="total-right-text">{{
              userInfoData.user_statistics.user_number
            }}</span>
            个
          </div>
        </div>

        <!--        表格-->
        <div v-if="userInfoData.data && userInfoData.data.length">
          <el-table
            class="table"
            ref="multipleTable"
            :data="userInfoData.data"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
            border
          >
            <el-table-column fixed prop="user_id" label="编号" width="72"> </el-table-column>
            <el-table-column label="头像" fixed width="70">
              <template slot-scope="scope">
                <img :src="scope.row.user.avatar" class="five-avatar" />
              </template>
            </el-table-column>
            <el-table-column label="用户" width="200">
              <template slot-scope="scope">
                <div class="white-space">昵称：{{ scope.row.user ? scope.row.user.nickname : '' }}</div>
                <div class="">等级：{{ scope.row.account.level }}级</div>
                <div class="" v-if="scope.row.current_circle && scope.row.current_circle.type === 1">身份：普通用户</div>
                <div class="" v-else-if="scope.row.current_circle && scope.row.current_circle.type === 2">身份：管理员</div>
                <div class="" v-else-if="scope.row.current_circle && scope.row.current_circle.type === 3">身份：超级管理员</div>
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="130">
              <template slot-scope="scope">
                <div>{{ scope.row.user.mobile }}</div>
              </template>
            </el-table-column>
            <!--            <el-table-column
                label="经验值"
                width="80">
              <template slot-scope="scope">
                <div>{{ scope.row.account.exp }}</div>
              </template>
            </el-table-column>-->
            <el-table-column label="邀请人" width="80">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.current_circle && scope.row.current_circle.inviter ? scope.row.current_circle.inviter.nickname : '' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="主播状态" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.is_presenter">
                  <span v-if="scope.row.presenter.status == -1">已驳回</span>
                  <span v-if="scope.row.presenter.status == 0">待审核</span>
                  <span v-if="scope.row.presenter.status == 1">已通过</span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column label="创作者状态" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.is_apply_author">
                  <span v-if="scope.row.author_status == 'reject'">已驳回</span>
                  <span v-if="scope.row.author_status == 'applied'">待审核</span>
                  <span v-if="scope.row.author_status == 'success'">已通过</span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column label="注册时间" width="160">
              <template slot-scope="scope">
                <div>{{ scope.row.created_at }}</div>
              </template>
            </el-table-column>
            <!--            <el-table-column
                label="登录次数"
                width="90">
              <template slot-scope="scope">
                <div>{{ scope.row.user.login_count }}</div>
              </template>
            </el-table-column>-->
            <el-table-column label="操作" align="center" fixed="right" width="100">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand" class="table-edit">
                  <span class="el-dropdown-link blue-font-color cursor-pointer"> 操作<i class="el-icon-arrow-down el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="['show', scope.row]">查看</el-dropdown-item>
                    <div
                      class=""
                      v-if="
                        ($store.state.user.userInfo.circle_relation.type === 2 || $store.state.user.userInfo.circle_relation.type === 3) &&
                        scope.row.user_id !== $store.state.user.userInfo.id
                      "
                    >
                      <el-dropdown-item
                        :command="['identity', scope.row]"
                        v-if="scope.row.current_circle && scope.row.current_circle.type != 3 && $store.state.user.userInfo.circle_relation.type === 3"
                        >{{ scope.row.current_circle.type == 1 ? '设置管理员' : '取消管理员' }}</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="['author', scope.row]"
                        v-if="scope.row.current_circle && scope.row.current_circle.type === 1 && (!scope.row.is_apply_author || scope.row.author_status !== 'applied')"
                        >{{ scope.row.user.is_author ? '取消创作者' : '设置创作者' }}</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="['review_creator', scope.row]"
                        v-if="scope.row.current_circle && scope.row.current_circle.type != 3 && scope.row.is_apply_author && scope.row.author_status === 'applied'"
                        >创作者审核</el-dropdown-item
                      >
                      <el-dropdown-item :command="['action', scope.row]" v-if="scope.row.presenter && scope.row.presenter.status == '1'">{{
                        scope.row.presenter.status == '1' ? '取消主播' : '设置主播'
                      }}</el-dropdown-item>
                    </div>
                    <el-dropdown-item :command="['examine', scope.row]" v-if="scope.row.presenter && scope.row.presenter.status == '0'">审核</el-dropdown-item>
                    <!--                    <el-dropdown-item :command="['account', scope.row]">交易</el-dropdown-item>-->
                    <div class="" v-if="$store.state.user.userInfo.circle_relation.type === 3 && scope.row.user_id !== $store.state.user.userInfo.id">
                      <el-divider></el-divider>
                      <el-dropdown-item :command="['del', scope.row]">移出频道</el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 数据空提示 -->
        <empty-prompt v-else></empty-prompt>

        <el-pagination
          v-if="userInfoData.meta && userInfoData.meta.last_page > 1"
          class="page"
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout=" prev, pager, next,total, jumper"
          :total="userInfoData.meta ? userInfoData.meta.total : 0"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!--    审核弹框-->
    <el-dialog class="dialog" title="审核" :visible.sync="isDialogExamine" top="20vh" width="658px" :destroy-on-close="true">
      <div class="three-list">
        <div class="three-list-item">
          <div class="three-list-item-header flex-nowrap">
            <img :src="current_info.user && current_info.user.avatar" class="three-list-item-header-img" alt="" />
            <div class="three-list-item-header-right">
              <div class="list-item-content space-between">
                <div class="list-item-content-left">
                  {{ current_info.user && current_info.user.nickname }}
                  <!--                  <span>（匿名）</span>-->
                </div>
              </div>
            </div>
          </div>
          <div class="three-list-item-content">
            于 {{ current_info.presenter && current_info.presenter.apply_at }} 申请成为主播，理由如下
            <div class="reply">
              <div class="reply-top space-between">
                <div class="reply-top-left">直播内容：</div>
              </div>
              <div class="reply-btm">{{ current_info.presenter && current_info.presenter.apply_reason }}</div>
            </div>
            <div class="" style="text-align: right; margin-top: 20px; font-weight: 400">
              <el-radio v-model="radio" label="1">通过</el-radio>
              <el-radio v-model="radio" label="2">驳回</el-radio>
            </div>
          </div>
          <div v-if="radio === '2'">
            <el-divider></el-divider>
            <div class="three-list-item-footer">
              <div class="dioTitle"><i class="el-icon-warning"></i> 你确定要驳回该申请吗？请选择驳回原因</div>
              <div class="delDiolog-item">
                <el-radio v-model="reason" label="1">内容出现违禁词</el-radio>
              </div>
              <div class="delDiolog-item">
                <el-radio v-model="reason" label="2">不符合直播标准</el-radio>
              </div>
              <div class="delDiolog-item">
                <el-radio v-model="reason" label="3">内容不符合《互联网管理办法》等相关法律法规</el-radio>
              </div>
              <div class="delDiolog-item">
                <el-radio v-model="reason" label="4">其他原因</el-radio>
              </div>
              <el-input type="textarea" :rows="2" v-model="reason_other" maxlength="100" placeholder="请输入原因" v-if="reason == '4'"> </el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isDialogExamine = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="examinAuthor()">确 定</el-button>
      </span>
    </el-dialog>
    <!--创作者审核弹框-->
    <el-dialog title="创作者申请审核" :visible.sync="dialogVisible" width="30%" class="dialog examine_dialog">
      <div class="examine">
        <div class="examine_item">
          <p class="label">实名认证</p>
          <p class="msg">{{ realName }}</p>
          <div class="box">已认证</div>
        </div>
        <div class="examine_item">
          <p class="label">职业认证</p>
          <p class="msg">{{ occupationName }}</p>
          <div class="box">已认证</div>
        </div>
        <div class="examine_item">
          <p class="label">审核状态</p>
          <el-radio-group v-model="examineRadio">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">不通过</el-radio>
          </el-radio-group>
        </div>
        <div class="examine_item reason" v-if="examineRadio === 2">
          <p class="label">不通过原因</p>
          <el-input class="reason_input" type="textarea" placeholder="请输入不通过原因" v-model="rejection_reason" maxlength="50" show-word-limit> </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeExaminAuthor">取 消</el-button>
        <el-button class="confirm" type="primary" @click="examinAuthor">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import EmptyPrompt from 'components/EmptyPrompt';
export default {
  name: 'Users',
  data() {
    return {
      dialogVisible: false,
      activeName: 'users',
      page: 1,
      examineRadio: 1,
      pageSize: 10,
      options: '', // 选项
      rejection_reason: '',
      realName: '',
      occupationName: '',
      optionsData: [
        {
          label: '用户ID',
          value: 'user_id',
        },
        {
          label: '昵称',
          value: 'nickname',
        },
        {
          label: '手机',
          value: 'mobile',
        },
      ],
      keywords: '', // 关键词
      options_status: '', // 身份,
      identity: '',
      author_status: '',
      optionsStatus: [
        {
          value: 'presenter',
          label: '主播状态',
        },
        {
          value: 'author',
          label: '创作者状态',
        },
      ],
      statusList: [
        {
          label: '待审核',
          value: 0,
        },
        {
          label: '已驳回',
          value: -1,
        },
        {
          label: '已通过',
          value: 1,
        },
      ],
      identityData: [
        {
          label: '普通用户',
          value: 1,
        },
        {
          label: '管理员',
          value: 2,
        },
        {
          label: '超级管理员',
          value: 3,
        },
      ], // 身份数据
      level: '', // 等级
      levelData: [], // 等级数据
      start_date: '', // 注册开始日期
      end_date: '', // 注册开始日期
      managed_circle_id: '',
      userInfoData: {}, // 用户数据信息
      current_info: {}, // 当前选择对象
      user_id: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      }, // 时间选择
      change_time: '', // 时间结果
      isDialogExamine: false, //审核弹框
      radio: '1',
      reason: '1',
      reason_other: '',
    };
  },
  created() {
    this.queryFun();
    this.getLeave();
    // if (this.userInfo && this.userInfo.id) {
    this.getUserInfo();
    // }
  },
  watch: {
    // userInfo() {
    // },
  },
  mounted() {
    if (this.userInfo && this.userInfo.id) {
      this.managed_circle_id = this.userInfo.managed_circle.user_id;
      this.getUserInfo();
    }
  },
  methods: {
    // 关闭审核弹框
    closeExaminAuthor() {
      this.dialogVisible = false;
      this.examineRadio = 1;
      this.rejection_reason = '';
    },
    // 确认审核创作者弹框
    examinAuthor() {
      let data = {};
      let url = `${this.$api.circles}/${this.user_id}/${this.$api.circlesExamine}`;
      if (this.examineRadio === 2) {
        data.approve_status = 'reject';
        if (this.rejection_reason === '') {
          this.$message({
            message: '请输入不通过原因',
            type: 'warning',
          });
          return;
        } else {
          data.rejection_reason = this.rejection_reason;
        }
      } else {
        data.approve_status = 'success';
        data.rejection_reason = '';
      }
      this.$http.post(url, data, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.$message.success('操作成功');
          this.linkFun();
          this.getUserInfo();
          this.closeExaminAuthor();
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      let url =
        this.$api.Users +
        `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}&identity=${this.identity}&author_status=${this.author_status}&start_date=${this.start_date}&end_date=${this.end_date}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          console.log(res.data.data, '===================');
          this.userInfoData = res.data.data;
        }
      });
    },
    // 查询
    searchSubmit() {
      this.page = 1;
      this.linkFun();
      this.getUserInfo();
    },
    // 重置
    resetFun() {
      this.options = '';
      this.keywords = '';
      this.level = '';
      this.author_status = '';
      // this.options_status = '';
      this.identity = '';
      this.start_date = '';
      this.end_date = '';
      this.change_time = '';
      this.searchSubmit();
    },
    // 列表操作
    handleCommand(command) {
      this.current_info = command[1];
      let type = command[0];
      if (type === 'show') {
        // 查看详情
        this.$router.push({ path: `/user/show/${this.current_info.user.uuid}` });
      } else if (type === 'identity') {
        // 设置管理员
        this.$confirm(`这是您操作比较重要的数据, 你确定要要将该用户${command[1].current_circle.type === 1 ? '设置为管理员么' : '取消为管理员么'}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let url = this.$api.Users + '/' + command[1].user.uuid + '/' + this.$api.changeIdentity;
            let data = {
              identity: command[1].current_circle.type === 1 ? 2 : 1,
            };
            this.$http
              .post(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('设置成功');
                  this.linkFun();
                  this.getUserInfo();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          })
          .catch(() => {});
      } else if (type === 'action') {
        // 设置主播
        this.$confirm(`这是您操作比较重要的数据, 你确定要要将该用户${command[1].presenter.status == '1' ? '取消为主播么' : '设置为主播么'}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let url = this.$api.Users + '/' + command[1].user.uuid + '/' + this.$api.changePresenter;
            let data = {
              action: command[1].presenter.status == '1' ? 'delete' : 'add',
            };
            this.$http
              .post(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('设置成功');
                  this.linkFun();
                  this.getUserInfo();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          })
          .catch(() => {});
      } else if (type === 'account') {
        // 交易
        this.$router.push({ path: `/user/account/${this.current_info.user.uuid}` });
      } else if (type === 'del') {
        // 移除小组
        this.$confirm(`这是您操作比较重要的数据, 你确定要将该用户移出小组？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let url = this.$api.Users + '/' + command[1].user.uuid + '/' + this.$api.removeCircle;
            this.$http
              .get(url, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('移出成功');
                  this.linkFun();
                  this.getUserInfo();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          })
          .catch(() => {});
      } else if (type === 'examine') {
        this.isDialogExamine = true;
        // this.examineForm =
      } else if (type === 'review_creator') {
        // 创作者审核
        this.dialogVisible = true;
        let uuid = command[1].user.id;
        this.user_id = command[1].user.uuid;
        let urlName = `${this.$api.realNameAuth}?user_id=${uuid}&is_visible=1`;
        let urlApprovals = `${this.$api.approvals}?user_id=${uuid}&is_visible=1`;
        this.$http.get(urlName, true).then((res) => {
          if (res.data.success) {
            this.realName = res.data.data.name;
          }
        });
        this.$http.get(urlApprovals, true).then((res) => {
          if (res.data.success) {
            this.occupationName = res.data.data.name;
          }
        });
      } else if (type === 'author') {
        // 设置作者
        this.$confirm(`这是您操作比较重要的数据, 你确定要要将该用户${command[1].user.is_author ? '取消作者么' : '设置为作者么'}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let action = command[1].user.is_author ? 'cancel' : 'set';
            let url = `${this.$api.setAuthor}?action=${action}&user_id=${command[1].user.id}`;
            let data = {};
            this.$http
              .post(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('设置成功');
                  this.linkFun();
                  this.getUserInfo();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          })
          .catch(() => {});
      }
    },
    //审核
    examine() {
      if (this.radio === '1') {
        let url = this.$api.Users + '/' + this.current_info.user.uuid + '/' + this.$api.reviewedPresenter;
        let data = {
          action: 'agree',
        };
        this.$http
          .post(url, data, true)
          .then((res) => {
            if (res.data.success) {
              this.$message.success('审核通过');
              this.isDialogExamine = false;
              this.getUserInfo();
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
          });
      } else {
        let other = '';
        if (this.reason === '1') {
          other = '内容出现违禁词';
        } else if (this.reason === '2') {
          other = '不符合直播标准';
        } else if (this.reason === '3') {
          other = '内容不符合《互联网管理办法》等相关法律法规';
        } else if (this.reason === '4') {
          other = this.reason_other;
        }
        let url = this.$api.Users + '/' + this.current_info.user.uuid + '/' + this.$api.reviewedPresenter;
        let data = {
          action: 'reject',
          rejection_reason: other,
        };
        this.$http
          .post(url, data, true)
          .then((res) => {
            if (res.data.success) {
              this.$message.success('驳回成功');
              this.isDialogExamine = false;
              this.getUserInfo();
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
          });
      }
    },
    // 分页当前也
    handleCurrentChange(val) {
      this.page = val;
      this.linkFun();
      this.getUserInfo();
    },
    // 获取等级
    getLeave() {
      let arr = [];
      let url = this.$api.Dictionary + '?key=account_level';
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          for (let i in res.data.data) {
            arr.push({
              label: res.data.data[i],
              value: i,
            });
          }
          this.levelData = arr;
        }
      });
    },
    // 时间选择
    timeChange() {
      if (this.change_time === null) {
        this.start_date = '';
        this.end_date = '';
      } else {
        this.start_date = this.$carbon.formatDate(this.change_time[0]);
        this.end_date = this.$carbon.formatDate(this.change_time[1]);
      }
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({
        path: '/user/user',
        query: {
          options: this.options,
          keywords: this.keywords,
          // level: this.level,
          status: this.status,
          options_status: this.options_status,
          page: this.page,
          pageSize: this.pageSize,
          start_date: this.start_date,
          end_date: this.end_date,
          change_time: this.change_time,
        },
      });
    },
    // 参数赋值
    queryFun() {
      let query = this.$route.query;
      if (query) {
        if (query.options) {
          this.options = query.options;
        }
        if (query.keywords) {
          this.keywords = query.keywords;
        }
        if (query.level) {
          this.level = query.level;
        }
        if (query.status) {
          this.status = query.status;
        }
        if (query.identity) {
          this.identity = parseInt(query.identity);
        }
        if (query.start_date) {
          this.start_date = query.start_date;
        }
        if (query.end_date) {
          this.end_date = query.end_date;
        }
        if (query.page) {
          this.page = parseInt(query.page);
        }
        if (query.pageSize) {
          this.pageSize = parseInt(query.pageSize);
        }
        if (query.change_time) {
          this.change_time = query.change_time;
        }
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  components: {
    EmptyPrompt,
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/user/index.less';
.rule {
  background: #ffffff;
}
</style>
